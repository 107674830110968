.search-bar {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f2f2f2;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .search-input {
    padding: 8px 10px;
    width: 70%;
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .search-button {
    padding: 10px 20px;
    background-color: navy;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  